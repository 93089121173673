!<template>
  <div class="main_container">
    <div class="col-12 bread-crumbs">
      <span>Mission de contrôle ></span>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Liste des missions de contrôle
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row mt-3">
      <div class="col-4">
        <button type="button"
                v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')" 
                class="btn btn-blue"
                data-toggle="modal"
                ref="modal_button"
                data-target="#exampleModal"
                @click="title='add'">Ajouter une mission de contrôle</button>
      </div>
      <div class="col-4">
        <button class="btn btn-blue pl-5 pr-5 op-btn"
                data-toggle="modal"
                ref="modal_button"
                data-target="#filtreModal">Filtrer</button>
      </div>
      <div class="col-4">
        <button class="btn btn-blue"
                @click="downloadExport(missionControls.url)"
                download>
          Exporter
        </button>
      </div>
    </div>    
    <div class="inner_container">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="th-blue">Mission de contrôle</th>
            <th class="th-blue"></th>
          </tr>
        </thead>
        <tbody v-if="long==true && longueur > 0">
          <tr v-for="(missionControl,pkey) in missionControls.donnees"
              :key="pkey">
            <td scope="row">{{missionControl.libelle}}</td>
            <td class="icon-blue text-right">
              <i class="flaticon-pencil"  
                 v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
                 @click="updateForm(missionControl.id)"
                 ref="modal_button"
                 data-toggle="modal"
                 data-target="#exampleModal"></i>
              <i class="flaticon-delete ml-4"
                 v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
                 @click="title='del',id_mission=missionControl.id"
                 data-toggle="modal"
                 data-target="#exampleModal"></i>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="long==true && longueur == 0">
          <tr>
            <td scope="row"
                colspan="2"
                class="text-center">Aucune donnée enregistrée.</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td scope="row"
                colspan="2"
                class="text-center">Chargement en cours...</td>
          </tr>
        </tbody>
      </table>
      <div class="row pagination">
        <div v-if="missionControls.pagination"
             class="col-md-2 mx-auto text-center">
          <span v-if="missionControls.pagination.precedent"
                class="badge badge-pill badge-info"
                @click="pIndex--, paging(missionControls.pagination.precedent)"> &#60; </span>
          {{ pIndex }}
          <span v-if="missionControls.pagination.suivant"
                class="badge badge-pill badge-info"
                @click="pIndex++, paging(missionControls.pagination.suivant)"> &#62; </span>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="title=='del'">Supprimer un mission de contrôle</h5>
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="title=='add'">Ajouter un mission de contrôle</h5>
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="title=='update'">Détail mission de contrôle</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body"
               v-if="title=='add'">
            <AddMissionControl @added="added => (ajout = added)"/>
          </div>
          <div class="modal-body"
               v-if="title=='update'">
            <UpdateMissionControl :id_missionControl="id_mission"
                                  @updated="updated => (modifier = updated)"/>
          </div>
          <div class="modal-body text-center"
               v-if="title=='del'" >
            Voulez-vous vraiment supprimer cette mission de contrôle?
          </div>
          <div class="modal-footer"
               v-if="title=='del'">
            <button type="button"
                    class="btn btn-secondary"
                    @click="destroyMissionControl()">Oui</button>
            <button type="button"
                    class="btn btn-primary"
                    data-dismiss="modal">Non</button>
          </div>
          <!-- affichage avenant -->
          
        </div>
      </div>
    </div>
    <!-- Modal filtre-->
    <div class="modal fade col-md-12"
         id="filtreModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="filtreModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="filtreModalLabel">Paramètres de filtre</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ListFilter :champs="chmp"/>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="filtrer()">filtrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  /* .table-striped tbody td,
  .table-striped thead th{
    font-size: 0.48em;
  } */
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"
import AddMissionControl from "./Add.vue"
import UpdateMissionControl from "./Update.vue"
import ListFilter from "@/components/shared/Filter"

export default {
  name:"MissionControls",
  components:{
    ListFilter,
    Notif,
    AddMissionControl,
    UpdateMissionControl
  },
  data:()=>({
    form:form({
      id:""
    }).rules({
      id:"required"
    }).messages({
      id:"Ce champs est requis."
    }),
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    filtre:[],
    chmp:[
      {
        valeur:"libelle",
        type:"caractere",
        table:"mission_suivi_controles",
        libelle:"libelle",
      },
    ],
    long:false,
    longueur:0,
    user:"",
    url:"",
    pIndex: 1,
    title:"",
    ajout:false,
    modifier:false,
    id_mission:""
  }),
  watch:{
    missionControls(){
      if(this.missionControls){
        this.long=true
        this.longueur=this.missionControls.donnees.length
        this.$refs["btn-close"].click()
      }
    },
    ajout(){
      if(this.ajout){
        this.notif.message = "Mission de contrôle créé avec succès."
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMissionControls("")
            this.getMissionControls()
            this.long=false
            this.longueur=0
            this.$refs["btn-close"].click()
          }.bind(this),
          3000
        ) 
      }
    },
    modifier(){
      if(this.modifier){
        this.notif.message = "Mission de contrôle modifié avec succès."
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMissionControls("")
            this.getMissionControls()
            this.long=false
            this.longueur=0
            this.$refs["btn-close"].click()
          }.bind(this),
          3000
        ) 
      }
    },
    msgSuccessMissionControl(){
      if(this.msgSuccessMissionControl){
        this.notif.message = "Mission de contrôle supprimé avec succès."
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMissionControls("")
            this.getMissionControls()
            this.long=false
            this.longueur=0
            this.$refs["btn-close"].click()
          }.bind(this),
          3000
        ) 
      }
    },
    msgErrorMissionControl(){
      if(this.msgErrorMissionControl){
        this.notif.message = this.msgErrorMissionControl
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgErrorMissionControl("")
          }.bind(this),
          3000
        ) 
      }
    }
  },
  created(){
    this.getMissionControls()
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
  },
  computed:{
    ...mapGetters(["missionControls","msgSuccessMissionControl","msgErrorMissionControl"])
  },
  methods:{
    ...mapActions(["getMissionControls","deleteMissionControl","pageMissions"]),
    ...mapMutations(["setMissionControls","setMsgSuccessMissionControl","setMsgErrorMissionControl"]),
    paging(url) {
      if (this.pIndex == 0) {
        this.pIndex = 1
      }
      // console.log(this.url)
      this.goodUrl = url.split("/")
      url = this.goodUrl[1]
      this.loaded = false
      this.long = null
      var count=1
      var used=0
      url+="&filtres=["  
      this.filtre.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              url+=","
              console.log(count)
            }
            url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      url+="]"
      this.setMissionControls("")
      this.pageMissions(url)
    },
    filtrer() {
      this.loaded = false
      this.long = null
      this.setMissionControls("")
      this.getMissionControls(this.filtre)
    },
    destroyMissionControl(){
      this.form.id=this.id_mission
      if (!this.form.validate().errors().any()) {
        this.deleteMissionControl(this.form.data)
      }
    },
    updateForm(missionControl){
      this.title=''
      this.id_mission=''
      this.id_mission=missionControl
      setTimeout(
        function() {
          this.title='update'
        }.bind(this),
        100
      ) 
    },
    downloadExport(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_UPLOAD+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
}
</script>