<template>
  <div>
    <form class="form_default">
      <div class="form-group">
        <label  class="req">Nom de la mission</label>
        <input
          type="text"
          name="libelle"
          v-model='form.libelle'
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
        >
        <span
          v-if="form.errors().has('libelle')"
          v-text="form.errors().get('libelle')"
          class="errorMsg">
        </span>
      </div>
      <div class="button_form">
        <button
          type="button"
          class="btn btn-f-blue enter_btn"
          @click='submit'>
          <i class="flaticon-floppy-disk"></i> Enregistrer
        </button>
        <button
          type="reset"
          class="btn btn-f-blue cancle_btn ml-4">
          <i class="flaticon-cancel"></i> Annuler
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import form from 'vuejs-form'
import {mapActions,mapGetters,mapMutations} from "vuex"

export default {
  name:"AddMissionControl",
  data:()=>({
    form:form({
      libelle:""
    }).rules({
      libelle:"required"
    }).messages({
      libelle:"Ce champs est requis!"
    })
  }),
  watch:{
    msgSuccessMissionControl(){
      if (this.msgSuccessMissionControl) {
        this.$emit("added",{added:true})
        this.setMsgSuccessMissionControl("")
      }
    }
  },
  computed:{
    ...mapGetters(["msgSuccessMissionControl"])
  },
  methods:{
    ...mapActions(["postMissionControl"]),
    ...mapMutations(["setMsgSuccessMissionControl"]),
    submit(){
      if (!this.form.validate().errors().any()) {
        this.postMissionControl(this.form.data)
      }
    }
  }
}
</script>

<style>
input[type="text"],
label,
select,
button{
  font-size: 0.6em;
}
input{
  height: fit-content;
}
</style>